import net from "@/Scripts/app/net";

/* eslint-disable */
class DataQualityClient {
  constructor() {
  }
  getFleetOverview() {
    return net.get("/DataQuality/FleetOverview");
  }
  getDataOutage() {
    return net.get("/DataQuality/DataOutage");
  }
  getDataOutageConfig() {
    return net.get("/DataQuality/DataOutage/Configuration");
  }
  updateDataOutageConfig(config) {
    return net.put("/DataQuality/DataOutage/Configuration", config);
  }
  getDataOutliers() {
    return net.get("/DataQuality/DataOutliers");
  }
  getDataOutliersTakeAction() {
    return net.get("/DataQuality/DataOutliers/TakeAction");
  }
  updateDataOutliersTakeActionAcknowledge(tags) {
    return net.put("/DataQuality/DataOutliers/TakeAction/Acknowledge", tags);
  }
  updateDataOutliersTakeActionRemove(tags) {
    return net.put("/DataQuality/DataOutliers/TakeAction/Remove", tags);
  }
  getDataOutliersViewHistory() {
    return net.get("/DataQuality/DataOutliers/ViewHistory");
  }
  updateDataOutliersViewHistory(actions) {
    return net.put("/DataQuality/DataOutliers/ViewHistory", actions);
  }
}

export default new DataQualityClient();
