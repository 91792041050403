import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import dataQualityClient from "Clients/data-quality-client.js";
import { FleetOverviewType } from "@/types/dataQuality/fleetOverview";
import { DataAvailabilityConfigType, DataAvailabilityType } from "@/types/dataQuality/dataAvailability";
import { DataOutliersTakeActionType, DataOutliersType, DataOutliersViewHistoryType } from "@/types/dataQuality/dataOutliers";

@Module({ namespaced: true, name: "DataQuality" })
class DataQuality extends VuexModule {
  private _loadingState = false;
  private _modalLoadingState = false;
  private _fleetOverview: FleetOverviewType[] = [];
  private _dataAvailability: DataAvailabilityType[] = [];
  private _dataAvailabilityConfig: DataAvailabilityConfigType[] = [];
  private _dataOutliers: DataOutliersType[] = [];
  private _dataOutliersTakeAction: DataOutliersTakeActionType[] = [];
  private _dataOutliersViewHistory: DataOutliersViewHistoryType[] = [];

  @Mutation
  public SET_LOADING_STATE(loading: boolean): void {
    this._loadingState = loading;
  }

  @Mutation
  public SET_MODAL_LOADING_STATE(loading: boolean): void {
    this._modalLoadingState = loading;
  }

  @Mutation
  public REFRESH_FLEET_OVERVIEW(fleetOverview: FleetOverviewType[]): void {
    this._fleetOverview = fleetOverview;
  }

  @Mutation
  public REFRESH_DATA_AVAILABILITY(dataAvailability: DataAvailabilityType[]): void {
    this._dataAvailability = dataAvailability;
  }

  @Mutation
  public REFRESH_DATA_AVAILABILITY_CONFIG(dataAvailabilityConfig: DataAvailabilityConfigType[]): void {
    this._dataAvailabilityConfig = dataAvailabilityConfig;
  }

  @Mutation
  public REFRESH_DATA_OUTLIERS(dataOutliers: DataOutliersType[]): void {
    this._dataOutliers = dataOutliers;
  }

  @Mutation
  public REFRESH_DATA_OUTLIERS_TAKE_ACTION(dataOutliersTakeAction: DataOutliersTakeActionType[]): void {
    this._dataOutliersTakeAction = dataOutliersTakeAction;
  }
  @Mutation
  public REFRESH_DATA_OUTLIERS_VIEW_HISTORY(dataOutliersViewHistory: DataOutliersViewHistoryType[]): void {
    this._dataOutliersViewHistory = dataOutliersViewHistory;
  }

  @Action({ rawError: true })
  public async refreshFleetOverview(): Promise<void> {
    let fleetOverview = [];
    try {
      this.context.commit("SET_LOADING_STATE", true);
      fleetOverview = await dataQualityClient.getFleetOverview();
      this.context.commit("REFRESH_FLEET_OVERVIEW", fleetOverview);
      this.context.commit("SET_LOADING_STATE", false);
    } catch (error) {
      throw ({ message: "Failed to refresh fleet overview -> ", error });
    }
  }

  @Action({ rawError: true })
  public async refreshDataAvailability(): Promise<void> {
    let dataAvailability = [];
    try {
      this.context.commit("SET_LOADING_STATE", true);
      dataAvailability = await dataQualityClient.getDataOutage();
      this.context.commit("REFRESH_DATA_AVAILABILITY", dataAvailability);
      this.context.commit("SET_LOADING_STATE", false);
    } catch (error) {
      throw ({ message: "Failed to refresh data availability -> ", error });
    }
  }

  @Action({ rawError: true })
  public async refreshDataAvailabilityConfig(): Promise<void> {
    let dataAvailabilityConfig = [];
    try {
      this.context.commit("SET_MODAL_LOADING_STATE", true);
      dataAvailabilityConfig = await dataQualityClient.getDataOutageConfig();
      this.context.commit("REFRESH_DATA_AVAILABILITY_CONFIG", dataAvailabilityConfig);
      this.context.commit("SET_MODAL_LOADING_STATE", false);
    } catch (error) {
      throw ({ message: "Failed to refresh data availability config -> ", error });
    }
  }

  @Action({ rawError: true })
  public async updateDataAvailabilityConfig(dataAvailabilityConfig: DataAvailabilityConfigType[]): Promise<void> {
    try {
      await dataQualityClient.updateDataOutageConfig(dataAvailabilityConfig);
    } catch (error) {
      throw ({ message: "Failed to update Data Availability Config", error });
    }
  }

  @Action({ rawError: true })
  public async refreshDataOutliers(): Promise<void> {
    let dataOutliers = [];
    try {
      this.context.commit("SET_LOADING_STATE", true);
      dataOutliers = await dataQualityClient.getDataOutliers();
      this.context.commit("REFRESH_DATA_OUTLIERS", dataOutliers);
      this.context.commit("SET_LOADING_STATE", false);
    } catch (error) {
      throw ({ message: "Failed to refresh data outliers -> ", error });
    }
  }

  @Action({ rawError: true })
  public async refreshDataOutliersTakeAction(): Promise<void> {
    let dataOutliersTakeAction = [];
    try {
      this.context.commit("SET_MODAL_LOADING_STATE", true);
      dataOutliersTakeAction = await dataQualityClient.getDataOutliersTakeAction();
      this.context.commit("REFRESH_DATA_OUTLIERS_TAKE_ACTION", dataOutliersTakeAction);
      this.context.commit("SET_MODAL_LOADING_STATE", false);
    } catch (error) {
      throw ({ message: "Failed to refresh data outliers take action -> ", error });
    }
  }

  @Action({ rawError: true })
  public async updateDataOutliersTakeActionAcknowledge(dataOutliersTakeAction: DataOutliersTakeActionType[]): Promise<void> {
    try {
      await dataQualityClient.updateDataOutliersTakeActionAcknowledge(dataOutliersTakeAction);
    } catch (error) {
      throw ({ message: "Failed to acknowledge Data Outliers Take Action", error });
    }
  }
  @Action({ rawError: true })
  public async updateDataOutliersTakeActionRemove(dataOutliersTakeAction: DataOutliersTakeActionType[]): Promise<void> {
    try {
      await dataQualityClient.updateDataOutliersTakeActionRemove(dataOutliersTakeAction);
    } catch (error) {
      throw ({ message: "Failed to remove Data Outliers Take Action", error });
    }
  }

  @Action({ rawError: true })
  public async refreshDataOutliersViewHistory(): Promise<void> {
    let dataOutliersViewHistory = [];
    try {
      this.context.commit("SET_MODAL_LOADING_STATE", true);
      dataOutliersViewHistory = await dataQualityClient.getDataOutliersViewHistory();
      this.context.commit("REFRESH_DATA_OUTLIERS_VIEW_HISTORY", dataOutliersViewHistory);
      this.context.commit("SET_MODAL_LOADING_STATE", false);
    } catch (error) {
      throw ({ message: "Failed to refresh data outliers view history -> ", error });
    }
  }

  @Action({ rawError: true })
  public async updateDataOutliersViewHistory(dataOutliersViewHistory: DataOutliersViewHistoryType[]): Promise<void> {
    try {
      await dataQualityClient.updateDataOutliersViewHistory(dataOutliersViewHistory);
    } catch (error) {
      throw ({ message: "Failed to update Data Outliers View History", error });
    }
  }

  public get fleetOverview(): FleetOverviewType[] {
    return this._fleetOverview;
  }

  public get dataAvailability(): DataAvailabilityType[] {
    return this._dataAvailability;
  }

  public get dataAvailabilityConfig(): DataAvailabilityConfigType[] {
    return this._dataAvailabilityConfig;
  }

  public get dataOutliers(): DataOutliersType[] {
    return this._dataOutliers;
  }

  public get dataOutliersTakeAction(): DataOutliersTakeActionType[] {
    return this._dataOutliersTakeAction;
  }

  public get dataOutliersViewHistory(): DataOutliersViewHistoryType[] {
    return this._dataOutliersViewHistory;
  }

  public get loadingState(): boolean {
    return this._loadingState;
  }

  public get modalLoadingState(): boolean {
    return this._modalLoadingState;
  }
}

export default DataQuality;
