




























































































































































































































































































































































































































































































import { Component, Vue, Ref } from "vue-property-decorator";
import store from "@/store";
// helpers
import moment from "moment";
import dateHelper from "Utilities/date-helper";
// types
import { ExtendedVessel } from "@/types/Vessel";
import { DashboardI } from "@/types/dashboard";
import { VesselDataWidgetColumn } from "@/types/VesselDataWidgetColumn";
import { VesselDataWidgetConfig as VesselDataConfig } from "@/types/VesselDataWidgetConfig";
import { Widget } from "@/types/widget";
//  components
import ManageDashboardsDialog from "@/components/ManageDashboardsDialog.vue";
import DateTimeRangePicker from "@/components/widgets/DateTimeRangePicker.vue";
import TimeSpanDialog from "@/components/widgets/TimeSpanDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/clients/User.module";
import VesselsModule from "@/store/clients/Vessels.module";
import IncidentsModule from "@/store/clients/Incidents.module";
import DashboardModule from "@/store/clients/Dashboard.module";
import VesselDataWidgetConfiguration from "@/store/clients/VesselDataWidgetConfig.module";
import LogDataModule from "@/store/clients/LogData.module";

const User = getModule(UserModule, store);
const Vessels = getModule(VesselsModule, store);
const Incidents = getModule(IncidentsModule, store);
const Dash = getModule(DashboardModule, store);
const VesselDataWidgetConfig = getModule(VesselDataWidgetConfiguration, store);
const LogData = getModule(LogDataModule, store);

@Component({
  components: {
    ManageDashboardsDialog,
    DateTimeRangePicker,
    TimeSpanDialog,
    ConfirmDialog,
  },
})
export default class Sidebar extends Vue {
  @Ref("manageDashboardsDialog") manageDashboardsDialog!: any;
  @Ref("confirmDelete") confirmDelete!: any;

  sidebarMini = null;
  generalTimespanDialog = false;
  selectedWidgets: Widget[] = [];
  widgets: Widget[] = [];
  fromAndToDatesCalculationType = "Averaged";
  widgetDateRange: { fromDate: string | null; toDate: string | null } = {
    fromDate: moment().startOf("year").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  };
  generalTimeSpanSettings = {
    fromDate: "",
    toDate: "",
    calculationType: "LastReceived",
    fromAndToDatesCalculationType: "",
  } as Partial<VesselDataWidgetColumn>;
  accumulatedAllowedUnits = ["rpm", "MT/hr", "MT/day", "knot", "kW", "kg/hr", "l/hr"];
  helpCategories = [
    {
      name: "What's new?",
      path: "VersionHistory",
    },
    {
      name: "Getting started",
      path: "GettingStarted",
    },
    {
      name: "Carbon intensity indicator (CII)",
      path: "CII",
    },
    {
      name: "Basic vessel modules",
      path: "Basic",
    },
    {
      name: "Diagnostics",
      path: "Diagnostics",
    },
    {
      name: "Compare vessels",
      path: "Compare",
    },
    {
      name: "Charter party",
      path: "CharterParty",
    },
    {
      name: "Notification centre",
      path: "Notifications",
    },
    {
      name: "Emission report",
      path: "EmissionReport",
    },
    {
      name: "Frequently asked questions (FAQ)",
      path: "FAQ",
    },
  ];

  async onGeneralTimeSpanSettingsSave(): Promise<void> {
    const confirmed = await this.confirmDelete.open(
      "Set general time span",
      `Are you sure you want to apply general time span for the widgets [ ${this.selectedWidgets.map(
        widget => widget.name
      )} ]? It will change time span for each column in the selected widgets, and can not be undone.`
    );

    if (!confirmed) return;

    for (var [index, config] of this.selectedWidgetsConfigs.entries()) {
      config.columns.forEach(column => Object.assign(column, this.generalTimeSpanSettings));
      config = await this.fetchCalculatedLogData(config);
      await VesselDataWidgetConfig.updateVesselDataWidgetConfig(config);
    }
    await VesselDataWidgetConfig.refreshVesselDataWidgetConfigs();

    this.widgets = [];
    this.selectedWidgets = [];
  }

  async fetchCalculatedLogData(config: VesselDataConfig): Promise<VesselDataConfig> {
    for (const [index, column] of config.columns.entries()) {
      const logVariableIds = column.logVariables.map(variable => variable.id);
      if (column.calculationType !== "FromAndToDates") {
        column.fromDate = moment.utc(new Date(), "YYYY-MM-DD").subtract(dateHelper.getDatesDiffAsHours(column.fromDate, column.toDate), "hours").format();
        column.toDate = moment.utc(new Date(), "YYYY-MM-DD").format();
      }
      let result;

      switch (column.calculationType) {
        case "LastReceived":
          result = await LogData.fetchLastReceivedLogData({
            logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
          });
          break;
        case "LastVoyageAveraged":
          result = await LogData.fetchLastVoyageAveraged({
            logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
          });
          break;
        case "LastVoyageAccumulated":
          result = await LogData.fetchLastVoyageAccumulated({
            logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
          });
          break;
        case "Averaged":
          result = await LogData.fetchAveragedCalculatedLogData({
            logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
            fromDate: column.fromDate,
            toDate: column.toDate,
          });
          break;
        case "Accumulated":
          result = await LogData.fetchAccumulatedCalculatedLogData({
            logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
            fromDate: column.fromDate,
            toDate: column.toDate,
          });
          break;
        case "FromAndToDates":
          if (column.fromAndToDatesCalculationType === "Averaged") {
            result = await LogData.fetchAveragedCalculatedLogData({
              logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
              fromDate: column.fromDate,
              toDate: column.toDate,
            });
          } else if (column.fromAndToDatesCalculationType === "Accumulated") {
            result = await LogData.fetchAccumulatedCalculatedLogData({
              logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
              fromDate: column.fromDate,
              toDate: column.toDate,
            });
          }
          break;
        default:
          break;
      }

      if (Boolean(result)) Vue.set(config.columns[index], "cachedResults", result);
    }
    return config;
  }

  get selectedWidgetsConfigs(): VesselDataConfig[] {
    const selectedWidgetConfigs: VesselDataConfig[] = [];
    this.selectedWidgets.forEach(widget => {
      const widgetConfig = VesselDataWidgetConfig.getConfigById(widget.configId);
      if (widgetConfig != null) selectedWidgetConfigs.push(widgetConfig);
    });
    return selectedWidgetConfigs;
  }

  get configsWithUnitError(): VesselDataConfig[] {
    const configsWithNotAllowedUnit: VesselDataConfig[] = [];
    this.selectedWidgetsConfigs.forEach(config => {
      let columnHasNotAllowedUnit = false;
      config.columns.forEach(column => {
        if (!column.logVariables.length) return;
        if (column.logVariables.some(variable => !this.isUnitAccAllowed(variable.unit?.caption))) {
          columnHasNotAllowedUnit = true;
        }
      });
      if (columnHasNotAllowedUnit) configsWithNotAllowedUnit.push(config);
    });
    return configsWithNotAllowedUnit;
  }

  get widgetsWithErrorNameList(): string[] {
    const widgetsWithError = this.selectedWidgets.filter(widget => this.configsWithUnitError.some(config => config.id === widget.configId));
    return widgetsWithError.map(widget => widget.name);
  }

  get isWidgetHasNotAllowedUnit(): string | undefined {
    if (
      (this.generalTimeSpanSettings.calculationType === "Accumulated" ||
        (this.generalTimeSpanSettings.calculationType === "FromAndToDates" && this.generalTimeSpanSettings?.fromAndToDatesCalculationType === "Accumulated") ||
        this.generalTimeSpanSettings.calculationType === "LastVoyageAccumulated") &&
      this.configsWithUnitError.length > 0
    ) {
      return `Widgets [ ${this.widgetsWithErrorNameList} ] columns has not allowed variable unit for currently selected calculation type.`;
    } else if (this.selectedWidgets.length === 0) {
      return "Please select widgets";
    } else return "";
  }

  isUnitAccAllowed(unit: string): boolean {
    return this.accumulatedAllowedUnits.includes(unit);
  }

  get isAdmin(): boolean {
    return User.isAdmin;
  }

  get isSuperOrAdmin(): boolean {
    return User.isSuperOrAdmin;
  }

  //returns vessels sorted alphabetically
  get vessels(): ExtendedVessel[] {
    return Vessels.extendedVessels.sort(function (x, y) {
      const a = x.name.toUpperCase(),
        b = y.name.toUpperCase();

      return a == b ? 0 : a > b ? 1 : -1;
    });
  }

  // Returns true if the DataQuality feature is enabled for any vessel
  get dataQualityFeatureEnabled(): boolean {
    if(Vessels?.extendedVessels?.length === 0) return false;

    return Vessels.extendedVessels.some((vessel) => vessel.features.some(feature => feature.name === "DataQuality"));
  }

  get vesselsLoadingState(): boolean {
    return Vessels.vesselsLoadingState;
  }

  get currentCompanyId(): number | null {
    return User.userCompanyId;
  }

  get personalDashboards(): DashboardI[] {
    const personalDahboards = Dash.dashboards.filter(d => d.userId === User.userId);

    // get saved dashboards sequence array
    const stringifiedDashboardListSequence: string | null = localStorage.getItem("dashboardListSequence");
    //  if not null or undefined
    if (stringifiedDashboardListSequence != null) {
      const dashboardListSequence: { id: number; sequence: number }[] = JSON.parse(stringifiedDashboardListSequence);
      personalDahboards.forEach(d => (d.sequence = dashboardListSequence.find(item => item.id === d.id)?.sequence));
      return personalDahboards.sort((a: any, b: any) => a.sequence - b.sequence);
    } else {
      return personalDahboards;
    }
  }

  get sharedDashboards(): DashboardI[] {
    return Dash.dashboards.filter(d => d.userId !== User.userId);
  }

  get activeDashboard(): DashboardI {
    return Dash.activeDashboard;
  }

  onSequenceChanged(sequence: { id: number; sequence: number }[]): void {
    Dash.updateDashboardsSequence(sequence);
  }

  isDashboardActive(dashboard: DashboardI): boolean {
    if (this.$route.name !== "Dashboard") return false;
    return dashboard.id === this.activeDashboard.id;
  }

  setVesselIconRotation(course: number): unknown {
    if (!course) return;
    return { transform: `rotate(${course}deg)` };
  }

  setVesselPerformanceStatus(vessel: ExtendedVessel): string | undefined {
    return vessel.performance?.performanceStatus;
  }

  setActiveDashboard(dashboardId: number): void {
    if (this.$route.name !== "Dashboard") this.$router.push("/");
    Dash.setActiveDashboard(dashboardId);
  }

  openManageDashboardsDialog(): void {
    this.manageDashboardsDialog.open();
  }

  openGeneralTimespanDialog(dashboardId: number): void {
    const dashboard = Dash.dashboardById(dashboardId);
    this.widgets = dashboard.widgets.filter(widget => {
      const widgetConfig = VesselDataWidgetConfig.getConfigById(widget.configId);
      if (widget.type?.template === "VesselDataWidget" && widget.active && widgetConfig?.vessels && widgetConfig.vessels.length > 0) {
        return widget;
      }
    });
    this.generalTimespanDialog = true;
  }

  closeGeneralTimespanDialog(): void {
    this.widgets = [];
    this.selectedWidgets = [];
  }

  async created(): Promise<void> {
    await Vessels.refreshExtendedVessels();
    await Incidents.getAllOpenIncidents();
    await Dash.refreshDashboards();
    if (this.$route.path !== "Dashboard") await Dash.initializeDashboard();
  }
}
