import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import moment from "moment";
import FoulingClient from "Clients/fouling-client";
import { SeaSurfaceTemperature } from "@/types/SeaSurfaceTemperature";
import { SpeedLossHistory } from "@/types/SpeedLossHistory";
import { SpeedLossStatistic } from "@/types/SpeedLossStatistic";
import { HullCoating, HullCoatingManufacturer, HullCoatingType } from "@/types/HullCoating";
import { FoulingChartConfig } from "@/types/FoulingChartConfig";
import { FoulingAddedConsumption } from "@/types/FoulingAddedConsumption";
import { FoulingEventImpact } from "@/types/FoulingEventImpact";
import { TempPoint } from "@/types/TempPoint";

@Module({ namespaced: true, name: "Fouling" })
class Fouling extends VuexModule {
  private _seaSurfaceTemperature: TempPoint[] = [];
  private _speedLossHistory: SpeedLossHistory[] = [];
  private _speedLossStatistics: SpeedLossStatistic[] = [];
  private _hullCoatingVessel: HullCoating | null = null;
  private _hullCoatingType: HullCoatingType[] = [];
  private _hullCoatingManufacturer: HullCoatingManufacturer[] = [];
  private _foulingChartConfig: FoulingChartConfig = {} as FoulingChartConfig;
  private _propulsionCurve: { xValues: number[]; yValues: number[] } = {} as { xValues: number[]; yValues: number[] };

  //  @Getters
  public get seaSurfaceTemperature(): TempPoint[] {
    return this._seaSurfaceTemperature;
  }

  public get speedLossHistory(): SpeedLossHistory[] {
    return this._speedLossHistory;
  }

  public get speedLossStatistics(): SpeedLossStatistic[] {
    return this._speedLossStatistics;
  }

  public get hullCoatingType(): HullCoatingType[] {
    return this._hullCoatingType;
  }

  public get hullCoatingManufacturer(): HullCoatingManufacturer[] {
    return this._hullCoatingManufacturer;
  }

  public get hullCoatingVessel(): HullCoating | null {
    return this._hullCoatingVessel;
  }

  public get speedHistoryStartYear(): string {
    if (!this._speedLossHistory.length) return moment().startOf("year").format("YYYY-MM-DD");
    const timestamps = this._speedLossHistory.map(item => (moment(item.timestamp).valueOf()));
    return moment(Math.min(...timestamps)).format("YYYY-MM-DD");
  }

  public get foulingChartConfig(): FoulingChartConfig {
    return this._foulingChartConfig;
  }

  public get propulsionCurve(): { xValues: number[]; yValues: number[]; } {
    return this._propulsionCurve;
  }

  //  @Actions
  @Action({ rawError: true })
  public async fetchSpeedLossHistory(query: { vesselId: number; fromDate?: string | null; toDate?: string | null }): Promise<SpeedLossHistory[]> {
    try {
      const data: SpeedLossHistory[] = await FoulingClient.getSpeedLossHistory(query);
      this.context.commit("SET_SPEED_LOSS_HISTORY", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch speed loss history", error });
    }
  }

  @Action({ rawError: true })
  public async fetchSeaSurfaceTemperature(query: { shipId: number; fromDate: string | null; toDate: string | null }): Promise<SeaSurfaceTemperature | undefined> {
    try {
      if (this.seaSurfaceTemperature.length) return;
      const data = await FoulingClient.getSeaSurfaceTemperature(query);
      this.context.commit("SET_SEA_SURFACE_TEMPERATURE", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch sea surface temperature", error });
    }
  }

  @Action({ rawError: true })
  public async updateSeaSurfaceTemperature(query: { shipId: number; fromDate: string | null; toDate: string | null }): Promise<void> {
    try {
      const data = await FoulingClient.getSeaSurfaceTemperature(query);
      this.context.commit("SET_SEA_SURFACE_TEMPERATURE", data);
    } catch (error) {
      throw ({ message: "Failed to update sea surface temperature", error });
    }
  }

  @Action({ rawError: true })
  public async fetchSpeedLossStatistics(vesselId: number): Promise<number> {
    try {
      const data = await FoulingClient.getSpeedLossStatistics(vesselId);
      this.context.commit("SET_SPEED_LOSS_STATISTICS", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch Speed Loss Statistics percentage", error });
    }
  }

  @Action({ rawError: true })
  public async fetchHullCoatingType(): Promise<void> {
    try {
      const data = await FoulingClient.getHullCoatingType();
      this.context.commit("SET_HULL_COATING_TYPE", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch Hull Coating Type", error });
    }
  }

  @Action({ rawError: true })
  public async fetchHullCoatingManufacturer(): Promise<void> {
    try {
      const data = await FoulingClient.getHullCoatingManufacturer();
      this.context.commit("SET_HULL_COATING_MANUFACTURER", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch Hull Coating Manufacturer", error });
    }
  }

  @Action({ rawError: true })
  public async fetchHullCoatingVessel(vesselId: number): Promise<HullCoating> {
    try {
      const data: HullCoating = await FoulingClient.hullCoatingVessel(vesselId);
      this.context.commit("SET_HULL_COATING_VESSEL", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch Hull Coating", error });
    }
  }

  @Action({ rawError: true })
  public async postHullCoatingVessel(payload: HullCoating): Promise<void> {
    try {
      await FoulingClient.postHullCoatingVessel(payload);
    } catch (error) {
      throw ({ message: "Failed to update Hull Coating", error });
    }
  }

  @Action({ rawError: true })
  public async fetchFoulingChartConfig(vesselId: number): Promise<FoulingChartConfig> {
    try {
      const data: FoulingChartConfig = await FoulingClient.foulingChartConfig(vesselId);
      this.context.commit("SET_FOULING_CHART_CONFIG", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch Fouling configuration", error });
    }
  }

  @Action({ rawError: true })
  public async updateFoulingChartConfig(payload: FoulingChartConfig): Promise<void> {
    try {
      if (payload.vesselId) {
        await FoulingClient.updateFoulingChartConfig(payload);
        await this.fetchFoulingChartConfig(payload.vesselId);
      }
    } catch (error) {
      throw ({ message: "Failed to update Benchmark", error });
    }
  }

  @Action({ rawError: true })
  public async fetchPropulsionEfficency(query: { vesselId: number; fromDate?: string | null; toDate?: string | null; condition: string; granularity?: string; }): Promise<any> {
    try {
      const data = await FoulingClient.getPropulsionEfficency(query);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch Propulsion Efficency", error });
    }
  }

  @Action({ rawError: true })
  public async fetchPropulsionEfficencyCurve(query: { vesselId: number; condition?: string | null; }): Promise<any> {
    try {
      const data = await FoulingClient.getPropulsionEfficencyCurve(query);
      this.context.commit("SET_PROPULSION_CURVE", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch Propulsion Efficency Curve", error });
    }
  }

  @Action({ rawError: true })
  public async fetchAddedFuelConsumption(vesselId: number): Promise<FoulingAddedConsumption[]> {
    try {
      const data = await FoulingClient.getAddedFuelConsumption(vesselId);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch Added Fuel Consumption", error });
    }
  }

  @Action({ rawError: true })
  public async fetchEventImpact(query: { vesselId: number, eventDate: string }): Promise<FoulingEventImpact[]> {
    try {
      const data = await FoulingClient.getEventImpact(query.vesselId, query.eventDate);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch event impact", error });
    }
  }

  //  @Mutations
  @Mutation
  public SET_SEA_SURFACE_TEMPERATURE(data: SeaSurfaceTemperature): void {
    const formattedWeatherData: TempPoint[] = [];
    const entries = Object.entries(data.seaSurfaceTemperature);

    for (let i = 0; i < entries.length; i++) {
      const key = entries[i][0];
      const value = entries[i][1];

      const latitude = data.latitude[key];
      const longitude = data.longitude[key];
      if (!latitude || !longitude) continue;
      const shipSpeed = data.shipSpeed[key];
      const timeSpent = data.timeSpent[key];
      const formattedWeatherDataItem = {
        timestamp: moment(key).valueOf(),
        temperature: value,
        latlng: [latitude, longitude],
        shipSpeed: shipSpeed,
        timeSpent: timeSpent,
      };
      formattedWeatherData.push(formattedWeatherDataItem);
    }
    this._seaSurfaceTemperature = formattedWeatherData;
  }

  @Mutation
  public CLEAR_SEA_SURFACE_TEMPERATURE(): void {
    this._seaSurfaceTemperature = [];
  }

  @Mutation
  public SET_SPEED_LOSS_HISTORY(data: SpeedLossHistory[]): void {
    this._speedLossHistory = data.sort((a: SpeedLossHistory, b: SpeedLossHistory): number => {
      const dateA = new Date(a.timestamp).getTime();
      const dateB = new Date(b.timestamp).getTime();
      return dateA - dateB;
    });
  }

  @Mutation
  public SET_SPEED_LOSS_STATISTICS(data: SpeedLossStatistic[]): void {
    this._speedLossStatistics = data;
  }

  @Mutation
  public SET_HULL_COATING_TYPE(data: HullCoatingType[]): void {
    this._hullCoatingType = data;
  }

  @Mutation
  public SET_HULL_COATING_MANUFACTURER(data: HullCoatingManufacturer[]): void {
    this._hullCoatingManufacturer = data.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  @Mutation
  public SET_HULL_COATING_VESSEL(data: HullCoating): void {
    this._hullCoatingVessel = data;
  }

  @Mutation
  public SET_FOULING_CHART_CONFIG(data: FoulingChartConfig): void {
    this._foulingChartConfig = data;
  }

  @Mutation
  public SET_PROPULSION_CURVE(data: { xValues: number[]; yValues: number[] }): void {
    this._propulsionCurve = data;
  }
}

export default Fouling;
